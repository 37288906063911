import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AUTH_ENTORNO } from 'src/environments/environment';
import { AUTHENTICACION } from '../util/constants';

@Injectable()
export class AuthGuard  {
  //private router: Router, 
  constructor(
    
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(AUTH_ENTORNO==AUTHENTICACION.TMS_SERVER){
      // El login viene del TMS y no se hace comprobacion alguna
      if(!sessionStorage.getItem('access_token')){
        this.router.navigateByUrl('/public/welcome');
        return false;
      }
      return true;
    }else{
        // El login viene del AUTH y miramos que sea correcto
        if (this.authService.hasValidAccessToken()
                &&  this.authService.hasValidIdToken()) {
        
          let check = route.data;
          if ((check != undefined) && (check["authorg"] != undefined) ) {
            let authorg : string = check["authorg"] as string;
            let authkey : string = check["authkey"] as string;
            let tk = this.authService.getUserAccessToken();
            let permissions = tk!.auth!.permissions;
            let permission = permissions![authorg]![authkey];
            return ((permission != undefined) && (permission.length > 0));
          }
          return true;
        } else {
          this.router.navigateByUrl('/public/welcome');//, { login: true }
          return false;
        }

      }


  }
  
}
