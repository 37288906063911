import { Injectable } from '@angular/core';
import { PaginatorIntlService } from './paginator-intl.service';
import { HierarchyOptionsService } from './hierarchy-options.service';
import { UtilService } from './util.service';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ComponentsStateService {

  constructor(
    private paginatorIntl: PaginatorIntlService,
    private hierarchyOptions: HierarchyOptionsService,
    private utils:UtilService,
    private legacyDialogs:MatDialog,
    private dialogs: Dialog
  ) {}

  update(){
    this.updateLabelsState();
    this.loadHierarchyOptions();
    this.utils.getRolsAndOperators();
  }

  updateLabelsState(){
    this.paginatorIntl.updateLabelsState();
  }
  
  loadHierarchyOptions(){
    this.hierarchyOptions.load();
  }

  clearData(){
    this.hierarchyOptions.cleanState();
    this.utils.cleanRolsAndOperators();
    this.legacyDialogs.closeAll();
    this.dialogs.closeAll();
  }
}
